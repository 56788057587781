
import { instance } from '@/utils/http.js'

// 根据科目题型
export const getQuestionTypeBySubject = function (params) {
  return instance({
    url: '/api/v1/resource/subject_to_qt',
    method: 'get',
    params
  })
}