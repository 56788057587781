import { instance } from '@/utils/http.js'

// 根据知识点科目获取章节
export const getChapterByKnowledge = function (data) {
  return instance({
    url: '/api/v1/resource/knowledge_chapter',
    method: 'post',
    data
  })
}

// 教材获取章节
export const getchapterByBook = function (params) {
  return instance({
    url: '/api/v1/combination/textbook_chapter',
    method: 'get',
    params
  })
}